<template>
  <section>
    <RegistrationGuard @onPasswordValidated="validatePassword" v-if="isGuideRegistration && !isUnlocked" />

    <section v-else class="form-group">
      <img class="logo mb-6 mt-2" src="../assets/logo.png" />
      <h1 class="is-blue page-title">Sign up</h1>
      <!-- <h4 v-if="!isGuideRegistration" class="is-light-purple is-size-9 mt-2">
        Are you a guider? <router-link class="is-blue pl-1" to="/guider/register">Sign up as guider</router-link>
      </h4>
      <h4 v-else class="is-light-purple is-size-9 mt-2">
        Are you a client? <router-link class="is-blue pl-1" to="/register">Sign up as client</router-link>
      </h4> -->

      <form class="box mt-6">
        <div v-if="validationErrors.length" class="notification is-danger">
          <button @click="resetError()" class="delete"></button>
          <div class="content">
            Please resolve the following error(s) before proceeding.
            <ul style="margin-top:0.3em; margin-left: 1em">
              <li v-for="(error, index) in validationErrors" :key="`error-${index}`" v-html="error" />
            </ul>
          </div>
        </div>

        <b-field label="Name">
          <b-input icon="user" icon-pack="fas" v-model="name" />
        </b-field>

        <b-field label="Email">
          <b-input icon="at" icon-pack="fas" v-model="email" autocomplete="email" type="email" />
        </b-field>

        <!-- <b-field label="Phone number">
        <b-input type="phone-number" v-model="phoneNumber"></b-input>
      </b-field> -->

        <b-field label="Password">
          <b-input icon="lock" icon-pack="fas" v-model="password" autocomplete="current-password" type="password"
            password-reveal>
          </b-input>
        </b-field>

        <b-button :loading="isLoading" @click.prevent="validate()" class="mb-3" type="is-primary" expanded>
          Sign up
        </b-button>

      </form>
      <p class="is-light-purple is-size-9 is-flex is-justify-content-center">
        Already have an account? <router-link class="is-blue pl-1" to="/login">Login</router-link>
      </p>

      <!-- <hr class="hr-text mt-6 mb-6" />

      <div class="is-flex is-justify-content-center">
        <b-button icon-pack="fab" icon-left="google" @click="googleSignup" type="is-primary" outlined>
          Sign up with Google
        </b-button>
      </div> -->

      <p class="is-light-purple is-size-8 mt-6 text-align--center">By signing up you accept our
        <br>
        <a href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FContractPartners.pdf?alt=media&token=285f172b-95d5-4ee5-b04b-e8264c32cf5a"
          target="_blank">Terms of Service</a>
        and
        <a href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FPrivacy%20policy.pdf?alt=media&token=e6925517-73ae-4405-bc01-feaeb8e1526d"
          target="_blank">Privacy Policy</a>
      </p>

    </section>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { UserActions, UsersStates, UsersMutations } from "../store/Storetypes";
import RegistrationGuard from "../Guiders/RegistrationGuard.vue";
export default {
  components: {
    RegistrationGuard
  },
  data() {
    return {
      name: null,
      email: null,
      password: null,
      //phoneNumber: null,
      validationErrors: [],
      isUnlocked: false,
      isGuideRegistration: true
    };
  },

  computed: {
    ...mapState({
      user: state => state.users[UsersStates.USER],
      isLoading: state => state.users[UsersStates.IS_LOADING],
    }),
    route() {
      return this.$route.name;
    },
    companyId() {
      return this.$route.params.companyId
    },
    isRegisteringAnEmployee() {
      return this.$route.name === "register-employee"
    }
  },
  mounted() {
    this.setIsCompanyRegistration(this.$route.params.companyId)
  },

  watch: {
    user: {
      handler: function () {
        if (!this.isUnlocked) return
        if (this.user.uid) return this.companyId ? this.$router.push({ name: "guider-upcoming-activities" }) : this.$router.push({ name: "company-profile" });
        /* 
                if (this.user.uid) return this.companyId ? console.log({ name: "guider-upcoming-activities" }) : console.log({ name: "company-profile" });
             */
      },
      deep: true
    }
  },

  /* created() {
    this.isGuideRegistration = this.$route.name === "register-guider" || "register-employee"
  }, */
  beforeDestroy() {
    this.setIsLoading(false)
  },
  methods: {
    ...mapActions({
      signUp: UserActions.SIGN_UP,
      googleSignUp: UserActions.SIGN_IN_WITH_GOOGLE
    }),
    ...mapMutations({
      setIsLoading: UsersMutations.SET_IS_LOADING
    }),
    resetError() {
      this.validationErrors = [];
    },
    setIsCompanyRegistration(hasCompanyId) {
      if (hasCompanyId) {
        this.isGuideRegistration = false
        this.isUnlocked = true
      }

    },
    validatePassword(isPasswordValid) {
      if (!isPasswordValid) return
      this.isUnlocked = !!this.companyId || isPasswordValid
    },
    validate() {
      // Clear the errors before we validate again
      this.resetError();
      // email validation
      if (!this.email) {
        this.validationErrors.push("<strong>E-mail</strong> cannot be empty.");
      }

      //name verification
      if (!this.name) {
        this.validationErrors.push("<strong>Name</strong> cannot be empty.");
      }

      /* if (!this.phoneNumber) {
        this.validationErrors.push(
          "<strong>Phone number</strong> cannot be empty."
        );
      } */
      if (/.+@.+/.test(this.email) != true) {
        this.validationErrors.push("<strong>E-mail</strong> must be valid.");
      }
      // password validation
      if (!this.password) {
        this.validationErrors.push("<strong>Password</strong> cannot be empty");
      }

      if (/.{6,}/.test(this.password) != true) {
        this.validationErrors.push(
          "<strong>Password</strong> must be at least 6 characters long"
        );
      }

      // when valid -> sign up
      if (this.validationErrors.length <= 0) {
        this.signUpUser();
      }
    },
    googleSignup() {
      this.googleSignUp(this.isGuideRegistration)
    },
    async signUpUser() {
      const userData = {
        email: this.email,
        password: this.password,
        displayName: this.name,
        //phoneNumber: this.phoneNumber,
        //  isGuider: this.isGuideRegistration,
        isGuider: true,
        companyId: this.companyId
      }

      await this.signUp(userData)
    },
  },
};
</script>

<style scoped lang="scss">
.hr-text {
  overflow: visible;
  /* For IE */
  padding: 0;
  border: none;
  border-top: medium #927BD0;
  color: #A29EBB;
  text-align: center;

  &:after {
    content: "Or";
    font-family: 'Inter', sans-serif;
    display: inline-block;
    position: relative;
    top: -1em;
    font-size: .75em;
    padding: 0 1.75em;
    background: white;
  }
}

hr {
  background-color: #EDEBF2;
}

.button.is-primary.is-outlined {
  border: 2px solid;
  width: 209px;
  border-radius: 6px;
  font-weight: 500;
}

.checkbox-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(min-content, 1fr));
}

.b-radio.radio:not(.button) {
  color: #0e3d4d;
  font-size: .9rem;
}

.logo {
  height: 32px;
  width: auto;
}

.notification {
  border: none;
}

.box {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

.form-group {
  margin-left: 25%;
  margin-right: 25%;
}

@media only screen and (max-width: 768px) {
  .box {
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }

  #app {
    width: 100%;
  }

  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
